.vc_custom_1599556688305 {
  background-image: url(../images/bg-13.jpg) !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-attachment: fixed;
}

#contact-us .sc_content_grey:after {
  bottom: 14%;
  height: 41em;
}

.contact-us-form {
  background-color: #ffba00;
  border-radius: 10px 0 0 10px;
}

.submit-btn {
  background-color: #00516f;
  border: 1px solid #00516f !important;
  border-radius: 3px;
}

.submit-btn:hover {
  color: #00516f !important;
  border: 1px solid #00516f !important;

}

@media screen and (max-width:767px) {
  .contact-us-form {

    border-radius: 15px 0;
  }

}