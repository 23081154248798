.banner_img {
    background-image: url(../images/terms-and-conditions-banner.jpg);
}

.banner_img::before {
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.6);
}

hr.hr {
    border-color: #929291;
    margin: 0;
}