.trx_addons_column-1_3 {
  padding-right: 0px !important;
}
.sc_price_columns_wrap {
  margin: auto 10px !important;
}
.sc_price_item {
  background: #ffba00 !important;
}
.vc_custom_1502446345581 {
  padding-top: 3.2rem !important;
  padding-bottom: 1.2rem !important;
  background-color: #ffba00 !important;
  margin: auto 50px !important;
}
.price-overlay {
  background-color: rgba(255, 255, 255, 0.2);
  position: absolute;

  width: 100%;
  height: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  z-index: 0;
}
.sc_price_item .sc_price_item_info {
  z-index: 5555;
  position: relative;
}
